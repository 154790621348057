exports.components = {
  "component---node-modules-ntnhan-30-dh-gatsby-theme-wp-child-pedidosya-src-ntnhan-30-dh-gatsby-theme-wp-parent-pages-prensa-js": () => import("./../../../node_modules/@ntnhan30dh/gatsby-theme-wp-child_pedidosya/src/@ntnhan30dh/gatsby-theme-wp-parent/pages/prensa.js" /* webpackChunkName: "component---node-modules-ntnhan-30-dh-gatsby-theme-wp-child-pedidosya-src-ntnhan-30-dh-gatsby-theme-wp-parent-pages-prensa-js" */),
  "component---node-modules-ntnhan-30-dh-gatsby-theme-wp-parent-src-pages-404-js": () => import("./../../../node_modules/@ntnhan30dh/gatsby-theme-wp-parent/src/pages/404.js" /* webpackChunkName: "component---node-modules-ntnhan-30-dh-gatsby-theme-wp-parent-src-pages-404-js" */),
  "component---node-modules-ntnhan-30-dh-gatsby-theme-wp-parent-src-pages-index-js": () => import("./../../../node_modules/@ntnhan30dh/gatsby-theme-wp-parent/src/pages/index.js" /* webpackChunkName: "component---node-modules-ntnhan-30-dh-gatsby-theme-wp-parent-src-pages-index-js" */),
  "component---node-modules-ntnhan-30-dh-gatsby-theme-wp-parent-src-pages-presse-js": () => import("./../../../node_modules/@ntnhan30dh/gatsby-theme-wp-parent/src/pages/presse.js" /* webpackChunkName: "component---node-modules-ntnhan-30-dh-gatsby-theme-wp-parent-src-pages-presse-js" */),
  "component---node-modules-ntnhan-30-dh-gatsby-theme-wp-parent-src-templates-blog-post-js": () => import("./../../../node_modules/@ntnhan30dh/gatsby-theme-wp-parent/src/templates/BlogPost.js" /* webpackChunkName: "component---node-modules-ntnhan-30-dh-gatsby-theme-wp-parent-src-templates-blog-post-js" */),
  "component---node-modules-ntnhan-30-dh-gatsby-theme-wp-parent-src-templates-category-page-js": () => import("./../../../node_modules/@ntnhan30dh/gatsby-theme-wp-parent/src/templates/CategoryPage.js" /* webpackChunkName: "component---node-modules-ntnhan-30-dh-gatsby-theme-wp-parent-src-templates-category-page-js" */),
  "component---node-modules-ntnhan-30-dh-gatsby-theme-wp-parent-src-templates-tag-page-js": () => import("./../../../node_modules/@ntnhan30dh/gatsby-theme-wp-parent/src/templates/TagPage.js" /* webpackChunkName: "component---node-modules-ntnhan-30-dh-gatsby-theme-wp-parent-src-templates-tag-page-js" */)
}

